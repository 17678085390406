<template>
    <div>
        <div 
            class="absolute top-0 right-0 w-full bgBoxOne"
            :style="{backgroundColor: selectedConcept.colors[0]}"
        ></div>
        <div 
            class="absolute top-0 right-0 w-full bgBoxTwo"
            :style="{backgroundColor: selectedConcept.colors[1]}"
        ></div>
        <div 
            class="absolute top-0 right-0 w-full bgBoxThree"
            :style="{backgroundColor: selectedConcept.colors[2]}"
        >
        </div>
        <div 
            class="absolute top-0 right-0 w-full bgBoxFour"
            :style="{backgroundColor: selectedConcept.colors[3]}"
        >
        </div>
        <div 
            class="absolute top-0 right-0 w-full bgBoxFive"
            :style="{backgroundColor: selectedConcept.colors[4]}"
        >
        </div>
        <div 
            class="absolute top-0 right-0 w-full bgBoxSix"
            :style="{backgroundColor: selectedConcept.colors[5]}"
        >
        </div>
    </div>
</template>

<script setup>
    import useRoadmapConcept from '@/views/screens/roadmap/strategicRoadmap/roadmapConcept/useRoadmapConcept.js'

    const { selectedConcept } = useRoadmapConcept()
</script>

<style scoped>
.bgBoxOne{
    height: 213px;
    transform-origin: 100% 100%;
    top: 0px;
    clip-path: polygon(0 0, 100% 0, 100% 1.1%, 0 100%);
}

.bgBoxTwo{
    height: 424px;
    transform-origin: 100% 100%;
    top: 0px;
    clip-path: polygon(0% 50.2%, 101% 0, 100% 1%, 0 100%);
}
.bgBoxThree{
    height: 634px;
    transform-origin: 100% 100%;
    top: 0px;
    clip-path: polygon(0% 66.8%, 101% 0, 100% 1%, 0% 100%);
}
.bgBoxFour{
    height: 634px;
    transform-origin: 100% 100%;
    top: 0px;
    clip-path: polygon(0% 100%, 100% 0%, 33% 100%);
}
.bgBoxFive{
    height: 634px;
    transform-origin: 100% 100%;
    top: 0px;
    clip-path: polygon(0% 149.9%, 100% 0%, 66.5% 99.9%);
}
.bgBoxSix{
    height: 634px;
    transform-origin: 100% 100%;
    top: 0px;
    clip-path: polygon(0% 296.9%, 100% -0.5%, 100% 100%);
}
</style>