    import BaseLayout from '@/views/layouts/BaseLayout';
    import Sidebar from '@/elements/leftSidebar/Sidebar.vue';
    import Navbar from '@/elements/leftSidebar/Navbar.vue';
    import Toolbar from '@/components/roadmap/strategicRoadmap/components/Toolbar.vue';
    import Header from '@/components/roadmap/strategicRoadmap/components/Header.vue';
    import GraphWrapper from '@/components/roadmap/strategicRoadmap/components/GraphWrapper.vue';
    import StyleAndFilter from '@/components/roadmap/strategicRoadmap/components/StyleAndFilter.vue'
    import LeftPart from '@/components/roadmap/strategicRoadmap/components/LeftPart.vue'
    import ModuleList from '@/components/roadmap/strategicRoadmap/components/ModuleList.vue'
    import Settings from '@/components/roadmap/strategicRoadmap/highLevelData/Settings.vue'


    /* Bellow components are will be used in sidebar */
    import Properties from '@/components/roadmap/strategicRoadmap/highLevelData/sidebar/LevelDetail.vue'

    export const sidebarComponents = {
        Properties,
        ModuleList,
        LeftPart
    }
    export {
        BaseLayout,
        Sidebar,
        Navbar,
        Toolbar,
        Header,
        GraphWrapper,
        StyleAndFilter,
        Settings
    }