<template>
  <svg
    width="1130"
    height="631"
    viewBox="0 0 1130 631"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_68_7362)">
      <path
        opacity="0.35"
        d="M0 0H373C319.672 366.024 470.277 457.101 1131 423V631H0V0Z"
        :fill="strategy.settings?.colors ? strategy.settings.colors[0] : ''"
      />
      <path
        opacity="0.35"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1131 439H1132V211.5C804.143 222.782 719 179.5 748.5 0H373C319.672 366.024 470.276 457.101 1131 423V439Z"
        :fill="strategy.settings?.colors ? strategy.settings.colors[1] : ''"
      />
      <path
        d="M373 0.5C320.714 368.5 473.499 459 1132 423"
        stroke="white"
        stroke-width="2"
      />
      <path
        opacity="0.35"
        d="M750.5 0H1132V210.5C917 214 698.501 226.5 750.5 0Z"
        :fill="strategy.settings?.colors ? strategy.settings.colors[2] : ''"
      />
      <path
        d="M749.501 1C716.578 178.194 802.941 224.277 1132.5 211"
        stroke="white"
        stroke-width="2"
      />
      <path
        opacity="0.2"
        d="M-0.838867 110.502L1083.98 4.54028"
        stroke="white"
        stroke-width="0.5"
        stroke-dasharray="4 4"
      />
      <path d="M0.116699 210.509L1132 -0.999573" stroke="white" />
      <path
        opacity="0.2"
        d="M0.0751953 313.518L1086.2 12.4569"
        stroke="white"
        stroke-width="0.5"
        stroke-dasharray="4 4"
      />
      <path d="M0.0336914 421.531L1132 -0.999695" stroke="white" />
      <path
        opacity="0.2"
        d="M-0.00195312 526.547L1096.78 16.0274"
        stroke="white"
        stroke-width="0.5"
        stroke-dasharray="4 4"
      />
      <path
        opacity="0.2"
        d="M192.93 633.586L1102 20"
        stroke="white"
        stroke-width="0.5"
        stroke-dasharray="4 4"
      />
      <path
        opacity="0.2"
        d="M585.831 627.672L1110.31 24.0471"
        stroke="white"
        stroke-width="0.5"
        stroke-dasharray="4 4"
      />
      <path
        opacity="0.2"
        d="M976.723 627.881L1124.26 28.3099"
        stroke="white"
        stroke-width="0.5"
        stroke-dasharray="4 4"
      />
      <path d="M378.21 632.999L1132 -1" stroke="white" />
      <path d="M756.779 633.746L1132 -1" stroke="white" />
      <path d="M-0.5 631L1132 -1" stroke="white" stroke-linecap="round" />
    </g>
    <rect
      x="1"
      y="1"
      width="1128"
      height="629"
      stroke="white"
      stroke-width="2"
    />
    <defs>
      <clipPath id="clip0_68_7362">
        <rect width="1130" height="631" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup>
import useHighLevelRoadmap from '@/views/screens/roadmap/strategicRoadmap/highLevelRoadmap/useHighLevelRoadmap.js'
const { strategy } = useHighLevelRoadmap(true)
</script>
