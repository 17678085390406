import { getSystemProperties as getSelectedModuleProperties, getProperties, getParameterBg as getParameterBackground } from '@/store/services/traits/parameter.js';
import { fullName } from '@/plugins/lodash';
import { isEmpty, capitalize } from 'lodash'
export const getBackground = (widget, sampleValue) => {
    if(sampleValue > 0) {
      sampleValue = sampleValue - .000001;
    }
    let background = ''
    if(widget.definition_style && sampleValue == 0) return widget.definition_style[0].bg
    if(widget.definition_style && sampleValue) {
        widget.definition_style.map(definition => {
            let range = definition.range.split('-')
            if(sampleValue >= parseInt(range[0]) && sampleValue <= parseInt(range[1])) {
              background = definition.bg
            }
        })
    }
    return background;
};

export const progress = (parameter) => {
    let sampleValue = null;
    if(parameter) {
        sampleValue = parameter.sample_value;
        if(sampleValue == 0) {
            return '0%'
        }else {
            return sampleValue * 10 +'%'
        }
    }
}

export const getTheGap = (flatData, item, itemIndex) => {
    if(itemIndex > 0) {
      let indexInFlat = flatData.findIndex(data => data.id === item.id);
      if(indexInFlat >= 0) {
        if(flatData[indexInFlat].gap) {
          return flatData[indexInFlat].gap;
        } else {
          return getParentGap(item.parent_id, flatData);
        }
      }
    }
}

const getParentGap = (id, flatData) => {
    let itemIndex = flatData.findIndex(data => data.id === id);
    if(itemIndex >= 0) {
      if(flatData[itemIndex].gap) {
        return flatData[itemIndex].gap;
      } else {
        return getParentGap(flatData[itemIndex].parent_id, flatData);
      }
    }
    return 'mt-3';
  }

export const deleteWidget = (widgetId, layout) => {
  let widgetIndex = layout.widgets.findIndex(widget => widget.id === widgetId);
  if(widgetIndex >= 0) {
    layout.widgets.splice(widgetIndex, 1);
  }
};


export const getSelectedParamName = (widget, module) => {
  let selectedPropertiesName = 'Not set'
  let params = widget.parameters
  let properties = module.properties
  let selectedProperty
  if(params.length && properties.length) {
    let properties = module.properties.find(item => item.id === params[0].property_id)
    if(properties) {
      selectedProperty = properties.property_collections.find(collection => collection.id == params[0].id)
    }
   if(selectedProperty) selectedPropertiesName = selectedProperty.title
  }
  return selectedPropertiesName
}

//have to refactor
export const getSelectedParam= (widget, module) => {
  let selectedPropertiesName={}
  let params = widget.parameters
  let properties = module.properties
  let selectedProperty
  if(params.length && properties.length) {
    let properties = module.properties.find(item => item.id === params[0].property_id)
    if(properties) {
      selectedProperty = properties.property_collections.find(collection => collection.id == params[0].id)
    }
   if(selectedProperty) selectedPropertiesName = selectedProperty
  }
  return selectedPropertiesName
}
//have to refactor

//
export const getSelectedParamTitle = ({id, property_id}, properties) => {
  let selectedPropertiesName = 'Not set'
  if(!id || !property_id) {
    return '';
  }
  let selectedProperty
  if(properties.length) {
    let selectedProperties = properties.find(item => item.id === property_id)
    if(selectedProperties) {
      selectedProperty = selectedProperties.property_collections.find(collection => collection.id == id)
    }
   if(selectedProperty) {
    selectedPropertiesName = selectedProperty.title
   }
  }
  return selectedPropertiesName
}
export const getValidParameters = (parameters) => {
  return parameters.filter(param => param.custom_param == 1 || param.custom_param == 3 || param.id !== null)
}

export const getParameterTitles = (parameters, properties, hasCustomTitle=false) => {
  let parameterTitles = []
  let parametersWithIdOrCustomProperty = getValidParameters(parameters)
  let selectedProperties = getSelectedModuleProperties(parametersWithIdOrCustomProperty, properties)
  let propertyCollections = getProperties(parametersWithIdOrCustomProperty, selectedProperties)

  if(hasCustomTitle) {
    parameterTitles = parametersWithIdOrCustomProperty.map((param, index) => {
      if(param.custom_param == 1 || param.custom_param == 3) {
        return {
          title: param.custom_title+`${param.custom_type == 3 ? '(%)' : ''}`,
          id: param.id
        }
      }
      return {
        title: propertyCollections[index]?.title ? propertyCollections[index].title : '',
        propertyCollectionId: propertyCollections[index]?.id ? propertyCollections[index].id : null,
        id: param.id
      }
    })
  }

  if(!hasCustomTitle) {
    parameterTitles = propertyCollections.map((item, index) => {
      return {
        title: item?.title ? item.title : '',
        propertyCollectionId: propertyCollections[index]?.id ? propertyCollections[index].id : null,
        id: item.id
      }
    })
  }
  return parameterTitles
}

export const peopleFullName = (people) => {
    if(isEmpty(people)) {
        return '';
    }
    if(!isEmpty(people.user)) {
        return fullName(people.user) || capitalize(people.email)
    }
    return capitalize(people.email) // Contains custom people name in email column
}

export const parameterNextValue = (item, parameterId, value, roadmap) => {
    let collectionParam = {};
    collectionParam.sc_id = item.id;
    collectionParam.scenario_id = roadmap.scenario_id;
    collectionParam.project_id = roadmap.project_id;
    collectionParam.property_collection_id = parameterId;
    collectionParam.id = parameterId;
    collectionParam.module_id = item.module_id;
    value = value + 2;
    collectionParam.value = Math.floor(value);
    if(value > 10) {
        collectionParam.value = 1;
    }
    return collectionParam;
}

export const getPropertyCollection = (collectionId, properties) => {
  let collection = null
  for(let i = 0; i<properties.length; i++) {
    let item = properties[i].property_collections.find(property => property.id == collectionId)
    if(item) {
      collection = item
      break
    }
  }

  return collection
}

export const getItemVisibility = (layout, item) => {
  if(layout.selectedPropertiesId && layout.propertyRange) {
    let data = item.properties?.find(el => el.property_collection_id === layout.selectedPropertiesId)
    if(data) {
      return data.sample_value >= layout.propertyRange.min && data.sample_value <= layout.propertyRange.max
    }
    if(item.properties == undefined) {
      item.properties = []
    }
    item.properties?.push({scenario_collection_id: item.id, property_collection_id: layout.selectedPropertiesId, sample_value: 0})
    return true;
  }
  return true;
}

export const getParameterTextDefinition = (value, definitionStyles) => {
  if(value > 0) {
      value = value - .000001;
  }
  let valueIndex = Math.floor(value / 2);
  if(valueIndex > 4) {
      valueIndex = 4;
  }
  if(definitionStyles[valueIndex]) {
      return definitionStyles[valueIndex].definition;
  }
  return null;
}

/**
 * Returns parameter value.
 *
 * @param {object} item item of filteredCollections.
 * @param {object} parameter selected parameter.
 */
 export const getParameterValue = (item, parameter) => {
  if (item && item.properties && item.properties.length > 0 && parameter && parameter.id) {
    let param = item.properties?.find(data => data.property_collection_id === parameter.id);
    if (param) {
      return parseFloat(param.sample_value).toFixed(2);
    }
    return '0.00';
  }
  return '0.00';
}

/**
 * Returns parameter value.
 *
 * @param {object} module module that contains properties.
 * @param {object} parameter selected parameter.
 */
export const getParameterBg = (value, definitionStyles, serialIndex = null) => getParameterBackground(value, definitionStyles, serialIndex = null)
