<template>
    <div class="flex items-center gap-x-10 pr-10">
<!--        <div class="flex items-center gap-x-4">-->
<!--            <SwitchComponent -->
<!--                class="opacity-50" -->
<!--                :value="settings.synchronized"-->
<!--                @onClick="updateSync"-->
<!--            />-->
<!--            <span class="fs-14 fw-700">Sync to system</span>-->
<!--        </div>-->

        <div class="flex items-center fs-14">
            <span class="fw-800">Property Set:</span>
            <select
                v-model="configuration.property_id"
                class="select bg-transparent fs-400  text-primary-one inst-dropdown param-dropdown">
                <option :value="null">Property Set</option>
                <option v-for="(parameter, index) in parameters" :key="index" :value="parameter.id">{{ parameter.title }}</option>
            </select>
        </div>
        <div class="flex items-center fs-14">
            <span class="fw-800">Parameter Set:</span>
            <select
                v-model="configuration.id"
                class="select bg-transparent fs-400  text-primary-one inst-dropdown param-dropdown"
                @change="$emit('onSelectParameter')"
            >
                <option :value="null">Default Timeline</option>
                <option
                        v-for="(parameter, parameterIndex) in getParameters"
                        :key="parameterIndex"
                        :value="parameter.id"
                    >
                        {{ parameter.title }}
                </option>
            </select>
        </div>
    </div>
</template>

<script setup>
import SwitchComponent from '@/elements/atom/SwitchComponent.vue'
import { computed } from 'vue';

const props = defineProps({
    parameters: Array,
    configuration: Object,
    settings: Object,
    updateSync: Function,
});

const getParameters = computed(() => {
    if(props.configuration.id) {
        let data = props.parameters.find(item => parseInt(item.id) === parseInt(props.configuration.property_id));
        return data?.property_collections || [];
    }
    return [];
});
</script>
