<template>
    <div class="pl-5">
        <div v-for="(l1, l1Index) in l2L3L4Data[index]" :key="`l1-${l1Index}`">
            <div v-if="!isEmpty(l1?.children)" v-for="(l2, l2Index) in l1.children" :key="`l2-${l1Index}-${l2Index}`">
                <h1 class="text-secondary-one fw-500 fs-14 mb-1">
                    <span class="fw-800" :style="{color: l2.background_color}">
                        {{ l2.index_id }}{{ l2.index_code }}
                    </span> 
                    {{ l2.title }}
                </h1>
                <div v-if="!isEmpty(l2?.children)" v-for="(l3, l3Index) in l2.children" :key="`l3-${l1Index}-${l2Index}-${l3Index}`">
                    <h1 class="text-secondary-one fw-500 fs-14 mb-1">
                        <span class="fw-800" :style="{color: l3.background_color}">
                            {{ l3.index_id }}{{ l3.index_code }}
                        </span> 
                        {{ l3.title }}
                    </h1>
                    <div v-if="!isEmpty(l3?.children)" v-for="(l4, l4Index) in l3.children" :key="`l4-${l1Index}-${l2Index}-${l3Index}-${l4Index}`">
                        <h1 class="text-secondary-one fw-500 fs-14 mb-1">
                            <span class="fw-800" :style="{color: l4.background_color}">
                                {{ l4.index_id }}{{ l4.index_code }}
                            </span>
                            {{ l4.title }}
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import useHighLevelRoadmap from '@/views/screens/roadmap/strategicRoadmap/highLevelRoadmap/useHighLevelRoadmap.js'
import { isEmpty } from 'lodash'

const { l2L3L4Data } = useHighLevelRoadmap()
defineProps({
    index: [Number, String]
})
</script>

<style lang="scss" scoped>

</style>