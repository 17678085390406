import { sidebarComponents as components } from './useImporter';
import { ref, computed } from 'vue';

export const useSidebar = () => {
    const sidebarItems = [
        {
            src: "/images/icons/build/navigation.svg",
            name: 'ModuleList'
        },{
            src: "/images/icons/build/properties.svg",
            name: 'Properties'
        }
    ]
    const currentSidebar = ref('ModuleList');
    const sidebarVisibility = ref(true);
    const propsData = ref({});
    const activeSidebarComponent = computed(() => components[currentSidebar.value]);
    const switchSidebarComponent = (value, data) => {
        if(currentSidebar.value == 'ModuleList' && value == 'ModuleList') {
            sidebarVisibility.value = false;
        }
        currentSidebar.value = value;
        propsData.value = data;
    }
    return {
        currentSidebar,
        sidebarVisibility,
        activeSidebarComponent,
        propsData,
        sidebarItems,
        // Methods
        switchSidebarComponent,
    }
}
