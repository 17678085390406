<template>
    <div class="border-t-2 border-white">
        <div v-for="index in 3" :key="index">
            <div 
                v-if="index <= 3" 
                class="py-8 border-b-2 border-white" 
                style="height: 212px; margin-top: 0;"
            >
                <div class="h-full overflow-y-auto">
                    <select 
                        v-if="systemData" 
                        @change="(e) => setData(e, systemData, (index-1))" 
                        v-model="selectedGroups[index-1]"
                        class="bg-transparent text-primary-one text-left w-full fw-800 fs-16 ml-5" 
                        style="outline: none;"
                    >
                        <option value="null">Select Group</option>
                        <option
                            v-for="firstLevel in systemData"
                            :key="firstLevel.id"
                            :value="firstLevel.id"
                        >
                            {{ firstLevel.index_id }}{{ firstLevel.index_code }} {{ firstLevel.title }}
                        </option>
                    </select>
                    <LeftSideItem :index="(index-1)" />
                </div>
            </div>
        </div>
    </div> 
</template>

<script setup>
import useHighLevelRoadmap from '@/views/screens/roadmap/strategicRoadmap/highLevelRoadmap/useHighLevelRoadmap.js'
import LeftSideItem from './LeftSideItem.vue';

const props = defineProps({
    systemData: Array
})
const { l2L3L4Data, selectedGroups, strategy, updateStrategy, callback } = useHighLevelRoadmap()
const setData = (e, systemData, index) => {
    let id = e.target.value
    let levelData = systemData.find(item => item.id == id)

    if(!levelData) levelData = {children: []}
    l2L3L4Data.value.splice(index, 1, [levelData])

    selectedGroups.value.splice(index, 1, id)

    strategy.value.settings.selectedGroups = selectedGroups.value
    updateStrategy(strategy.value)
}
callback.value = () => {
    selectedGroups.value.forEach((systemDataId, index) => {
        if(!systemDataId) return
        let levelData = props.systemData.find(item => item.id == systemDataId)
        l2L3L4Data.value.splice(index, 1, [levelData])
    })
}

</script>

<style scoped>
.left{
   min-width: 298px;
}
</style>