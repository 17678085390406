import router from '@/routes/routes.js'
import axios from 'axios'
import { ref, set, computed, onMounted } from 'vue'
import { useProjectData } from '@/components/dashboard/sidebar/useProjectData.js'
import store from '@/store'
import { get, isEmpty } from 'lodash'

const l2L3L4Data = ref([[],[],[],[],[],[]])
const selectedGroups = ref([null, null, null, null, null, null])
const strategy = ref({})
const showCircleDetail = ref(false)
const isLoading = ref(false)

export default function useHighLevelRoadmap() {
    const callback = ref(()=>{})
    const route = router.currentRoute;
    const { getScenariosByProject, getModulesByProject, getPropertiesByModule} = useProjectData()
    const systemData = ref({})
    const parameters = ref([])
    const scenarios = ref([])
    const modules = ref([])
    const projectId = computed(()=> route.params.id)
    const module = computed(()=> modules.value.find(({id}) => id == strategy.value.module_id))


    const getStrategy = async (projectId) => {
        const { status, data } = await axios.get('/roadmaps/strategies/'+projectId)
        if(status) {
            strategy.value = data.data
            if(strategy.value.settings?.selectedGroups){
                selectedGroups.value = strategy.value.settings.selectedGroups
                callback.value()
            }

            if(!get(strategy.value.settings, 'colors')){
                strategy.value.settings = {
                    ...strategy.value.settings,
                    colors: ['', '', '', '', '', '']
                }
            }
            
            _checkSettings(strategy.value)
            setSystemData(strategy.value)
            setParameters(strategy.value)
        }
    }

    const updateStrategy = async (strategy) => {
        await axios.post('/roadmaps/strategies/update/'+strategy.id, strategy)
    }

    const setSystemData = async (config) => {
        if(!config.scenario_id) return
        const params = _prepareParamsForSystemData(config)
        systemData.value = await fetchSystemData(config.scenario_id, params)
    }

    const setParameters = async ({module_id}) => {
        if(!module_id) return
        parameters.value = await getPropertiesByModule(module_id)
    }

    const setModuleId = (moduleId) => {
        if(!moduleId) return
        strategy.value.module_id = moduleId
        handleModuleChange()
    }

    const fetchSystemData = async (scenarioId, params) => {
        return axios.get(`/roadmaps/strategies/system-data/${scenarioId}`, {
            params: {...params}
       }).then(({ data }) => data.data);
    }

    const handleModuleChange = () => {
        updateStrategy(strategy.value)
        setSystemData(strategy.value)
        setParameters(strategy.value)
        _resetParams(strategy.value)
    }

    const handleScenarioChange = () => {
        updateStrategy(strategy.value)
        setSystemData(strategy.value)
    }

    const handleParameterChange = async () => {
        const parameter = strategy.value.parameters && strategy.value.parameters[0] ? strategy.value.parameters[0].id : null
        await updateStrategy(strategy.value)
        const params = _prepareParamsForSystemData(strategy.value)
        if(!parameter) {
            params.reset = 1
            delete params.strategy_id
        }
        fetchSystemData(strategy.value.scenario_id, params)

        console.log('hitting.....');
        // fetchSystemDataOnParamChange()
    }

    const _prepareParamsForSystemData = (config) => {
        const { module_id, parameters } = config
        let params = {phases: 1}
        if(module_id) params.module_id = module_id
        params.parameter = (parameters && parameters.length) ? 1 : 0
        params.synchronized = 1
        params.strategy_id = config.id
        return params
    }

    const _checkSettings = (strategy) => {
        if(!strategy.settings || !('synchronized' in strategy.settings)) {
            strategy.settings = {}
            set(strategy.settings, 'synchronized', false)
        }

        if(!strategy.parameters) {
            strategy.parameters = []
            strategy.parameters.push({id: null, property_id: null})
        }
    }

    const _resetParams = (strategy) => {
        strategy.parameters.splice(0, 1, {id: null, property_id: null})
    }
    const updateSync = (val) => {
        strategy.value.settings.synchronized = val
        updateStrategy(strategy.value)
        setSystemData(strategy.value)
    }
    const updateLevelData = (item, params) => {
        axios.post(`projects/scenarios/${item.scenario_id}/update/${item.id}`, {
            ...params,
            id: item.id,
            title: item.title
        })
    }

    onMounted(async () => {
        getStrategy(projectId.value)
        scenarios.value = await getScenariosByProject(projectId.value)
        modules.value = await getModulesByProject(projectId.value)
        if(isEmpty(store.getters['project/project'])){
            store.dispatch('project/getSolidObject', projectId.value)
        }
    })

    return {
        route,
        strategy,
        scenarios,
        modules,
        module,
        systemData,
        setSystemData,
        handleModuleChange,
        updateStrategy,
        parameters,
        setModuleId,
        handleScenarioChange,
        updateSync,
        handleParameterChange,
        l2L3L4Data,
        showCircleDetail,
        updateLevelData,
        selectedGroups,
        callback,
        getStrategy,
        isLoading
    }
}
