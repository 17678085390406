<template>
    <div
        :style="{borderColor: getColor}"
        class="relative cursor-pointer"
        :class="wrapperClass"
        @click="onClick"
    >
        <div
            :style="{backgroundColor: getColor}"
            :class="[value ? 'slide-ball-right' : 'slide-ball-left']"
            class="slide-ball rounded-full absolute">
        </div>
    </div>
</template>

<script setup>
    import { computed } from 'vue';
    const props = defineProps({
        value: Boolean,
        wrapperClass: {
            default: 'switch-wrapper'
        },
        color: {
            typ: String,
            default: '#1c1c1d'
        }
    });
    const emit = defineEmits(['input', 'onClick'])
    const getColor = computed(() => props.value ? '#2B80AD' : props.color);


    const onClick = () => {
        emit('input', !props.value);
        emit('onClick', !props.value);
    }
</script>

<style scoped>
    .switch-wrapper {
        height: 24.48px;
        width: 48.95px;
        border: 1px solid;
        border-radius: 15px;
    }
    .slide-ball {
        height: 20px;
        width: 20px;
        transition-duration: .5s;
    }
    .slide-ball-left {
        left: 1px;
        top: 1px;
    }
    .slide-ball-right {
        right: 1px;
        top: 1px;
    }
</style>