<template>
    <div class="circle_wrapper relative w-full h-10 transform origin-top-right" >
        <template v-if="!isEmpty(levelData)" v-for="(item, l1Index) in levelData">
            <CircleBox 
                v-if="getLeftPosition(item) >= 0 && getLeftPosition(item) <= 100"
                :key="l1Index" 
                :title="item.index_id+item.index_code" 
                :item="item" 
                :bg="item.background_color" 
                :left="`${getLeftPosition(item)}%`"
                :style="{rotate: rotate ? rotate : '5.8deg'}"
                :class="!isEmpty(item?.children) && 'pointer-events-none'"
                @onDragEnd="update"
            />

            <template v-if="!isEmpty(item?.children)" v-for="(l2, l2Index) in item.children">
                <CircleBox
                    v-if="getLeftPosition(l2)>=0 && getLeftPosition(l2) <= 100"
                    :key="'l2-'+l2Index" 
                    :title="l2.index_id+l2.index_code" 
                    :item="l2" 
                    :bg="l2.background_color" 
                    :left="`${getLeftPosition(l2)}%`"
                    :style="{rotate: rotate ? rotate : '5.8deg'}"
                    :class="!isEmpty(l2?.children) && 'pointer-events-none'"
                    @onDragEnd="update"
                />

                <template v-if="!isEmpty(l2?.children)" v-for="(l3, l3Index) in l2.children">
                    <CircleBox
                        v-if="getLeftPosition(l3)>=0 && getLeftPosition(l3) <= 100"
                        :key="`l3-${l2Index}-${l3Index}`"
                        :title="l3.index_id+l3.index_code" 
                        :item="l3" 
                        :bg="l3.background_color" 
                        :left="`${getLeftPosition(l3)}%`"
                        :style="{rotate: rotate ? rotate : '5.8deg'}"
                        :class="!isEmpty(l3?.children) && 'pointer-events-none'"
                        @onDragEnd="update"
                    />
                    <template v-if="!isEmpty(l3?.children)" v-for="(l4, l4Index) in l3.children">
                        <CircleBox
                            v-if="getLeftPosition(l4)>=0 && getLeftPosition(l4) <= 100"
                            :key="`l4-${l2Index}-${l3Index}-${l4Index}`" 
                            :title="l4.index_id+l4.index_code" 
                            :item="l4" 
                            :bg="l4.background_color" 
                            :left="`${getLeftPosition(l4)}%`"
                            :style="{rotate: rotate ? rotate : '5.8deg'}"
                            :class="!isEmpty(l4?.children) && 'pointer-events-none'"
                            @onDragEnd="update"
                        />
                    </template>
                </template>
            </template>
        </template>
    </div>
</template>

<script setup>
import router from '@/routes/routes.js'
import axios from 'axios'
import { onMounted, ref } from 'vue'
import { isEmpty } from 'lodash'
import CircleBox from '@/components/roadmap/strategicRoadmap/hightLevelRoadmap/CircleBox.vue'
import useHighLevelRoadmap from '@/views/screens/roadmap/strategicRoadmap/highLevelRoadmap/useHighLevelRoadmap.js'
import { getParameterValue } from '@/store/modules/roadmap/services'

const route = router.currentRoute;
let props = defineProps({
    systemDates: Object,
    levelData: Array,
    rotate: {
        type: String
    },
    width: {
        type: [Number, String],
        default: 1134
    }
})

const { strategy, updateLevelData, getStrategy, isLoading } = useHighLevelRoadmap()
const totalDays = ref(0)

const getDifferenceInDays = (date1, date2) => 
{
  const diffInMs = Math.abs(date2 - date1)
  return diffInMs / (1000 * 60 * 60 * 24)
}

const getLeftPosition = (item) => 
{
    let position = _getParameterWisePosition(strategy.value, item)
    if(position){
        return position
    }

    if(!item?.start_date) return 0
    let days = getDifferenceInDays(new Date(props.systemDates.phase_start_date), new Date(item.start_date))
    position = `${(100 * days) / totalDays.value}`

    return position
}

const _getParameterWisePosition = (strategy, levelData) => {
    if (strategy.parameters.length
        && strategy.parameters[0].id
        && strategy.parameters[0].property_id
    ) {
        const parameterValue = getParameterValue(levelData, strategy.parameters[0])
        
        let singlePercent = props.width / 100
        return parameterValue * singlePercent
    }
}

const update = async (levelData, target) => 
{
    if (
        strategy.value.parameters.length
        && strategy.value.parameters[0].id
        && strategy.value.parameters[0].property_id
    ){
        isLoading.value = true
        let payload   = {
            module_collection_id: null,
            module_id: levelData.module_id,
            property_collection_id: strategy.value.parameters[0].id,
            sample_value: _getSampleValue(target, props.width),
            scenario_collection_id: levelData.id,
            scenario_id: levelData.scenario_id
        }

        await axios.post('properties/updateFromScenario', payload)
        isLoading.value = false
        getStrategy(route.params.id)
        return
    }
    _handleDate(levelData, target)
}

const _getSampleValue = (target, parentWidth) => {
    let targetLeft  = target.offsetLeft + 20
    let circlePositionInPercentage = (100 * targetLeft)/parentWidth
    return (10 * circlePositionInPercentage)/100
}
const _handleDate = (levelData, target) => {
    let parent = target.parentElement
    let parentWidth = parent.offsetWidth
    let targetLeft  = target.offsetLeft + 20

    let leftInPercentage = (100 * targetLeft) / parentWidth

    let currentDays = (totalDays.value * leftInPercentage) / 100

    let date = new Date(props.systemDates.phase_start_date)
        date.setDate(date.getDate() + currentDays)
        date = `${date.getFullYear()}-${formateMonth(date.getMonth()+1)}-${formateDate(date.getDate())}`
    
    updateLevelData(levelData, {start_date: date})
}

const formateMonth = (month) => {
    return month < 10 ? `0${month}` : month
}

const formateDate = (date) => {
    return date < 10 ? `0${date}` : date
}

onMounted(() => {
    let startDate = new Date(props.systemDates.phase_start_date)
    let endDate = new Date(props.systemDates.phase_start_date)
    endDate.setMonth(endDate.getMonth() + 18)
    totalDays.value = getDifferenceInDays(startDate, endDate)
})
</script>

<style scoped>
    .circle_wrapper{
        rotate: -5.8deg;
        translate: -7px -20px;
        width: 1134px;
    }
</style>