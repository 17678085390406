<template>
    <div class="grid grid-cols-3 space-y-3 py-4 " style="margin-left: 7px;">
        <template v-for="index in 6">
            <div
                :key="index"
                v-if="index > 3"
                class="px-10 overflow-y-auto border-l-2 border-white" 
                :class="(index == 6) && 'border-r-2 border-white'"
                style="width: 378px; height: 120px; margin-top: 0;"
            >
                <div v-if="index > 3" :key="index">
                    <select 
                        v-if="systemData" 
                        @change="(e) => setData(e, systemData, index-1)" 
                        v-model="selectedGroups[index-1]"
                        class="bg-transparent text-primary-one text-left w-full fw-800 fs-16 -ml-1" 
                        style="outline: none;"
                    >
                        <option :value="null">Select Group</option>
                        <option
                            v-for="firstLevel in systemData"
                            :key="firstLevel.id"
                            :value="firstLevel.id"
                        >
                            {{ firstLevel.index_id }}{{ firstLevel.index_code }} {{ firstLevel.title }}
                        </option>
                    </select>
                    <BottomSideItem :index="(index-1)" />
                </div>
            </div>
        </template>
    </div>
</template>

<script setup>
import { isEmpty } from 'lodash'
import useHighLevelRoadmap from '@/views/screens/roadmap/strategicRoadmap/highLevelRoadmap/useHighLevelRoadmap.js'
import BottomSideItem from './BottomSideItem.vue';

const props = defineProps({
    systemData: Array
})
const { l2L3L4Data, selectedGroups, strategy, updateStrategy, callback } = useHighLevelRoadmap()
const setData = (e, systemData, index) => {
    let id = e.target.value
    let levelData = systemData.find(item => item.id == id)

    if(!levelData) levelData = {children: []}
    l2L3L4Data.value.splice(index, 1, [levelData])

    selectedGroups.value.splice(index, 1, id)
    strategy.value.settings.selectedGroups = selectedGroups.value
    updateStrategy(strategy.value)
}

callback.value = () => {
    selectedGroups.value.forEach((systemDataId, index) => {
        if(!systemDataId) return
        let levelData = props.systemData.find(item => item.id == systemDataId)
        l2L3L4Data.value.splice(index, 1, [levelData])
    })
}
</script>