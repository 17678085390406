<template>
    <div 
        v-if="left && title"
        @mousemove="mouseMoveHandler"
        @mousedown="mouseDownHandler"
        @click="_showCircleDetail = true"
        v-click-outside="() => _showCircleDetail = false"
        class="w-10 h-10 top-0 rounded-full absolute circle flex items-center justify-center text-white fs-8 fw-600 cursor-move select-none"
    >
        {{ title }}
        <div v-if="showCircleDetail || _showCircleDetail" class="absolute bg-primary-three shadow-one rounded left-full ml-2">
            <div 
                class="flex items-center whitespace-nowrap"
                :class="showCircleDetail ? 'py-0 px-1' : 'py-1 px-2'"
            >
                <div class="w-2 h-2" :style="{background: bg}"></div>
                <h4 class="text-secondary-one ml-2" :class="showCircleDetail ? 'fs-10' : 'fs-12'"> 
                    {{ title }} {{ item.title }} 
                </h4>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted, onUnmounted } from 'vue'
    import useHighLevelRoadmap from '@/views/screens/roadmap/strategicRoadmap/highLevelRoadmap/useHighLevelRoadmap.js'

    const props = defineProps({
        title: [String, Number],
        bg: String,
        left: String,
        item: Object,
    })

    const { showCircleDetail } = useHighLevelRoadmap()
    const emit = defineEmits('onDragEnd')

    const _showCircleDetail = ref(false)
    const isMouseDown = ref(false)
    let prevPosition = null
    let mousePosition = {x:null, y:null}
    let parentWidth   = null
    let selectedCircle   = null

    const mouseDownHandler = (e) => {
        isMouseDown.value = true
        selectedCircle = e.target
        selectedCircle.parentElement.style.zIndex = 10
        selectedCircle.style.zIndex = 11

        prevPosition = getComputedStyle(selectedCircle).left
        prevPosition = Number(prevPosition.replaceAll('px', ''))

        mousePosition.x = e.x
        mousePosition.y = e.y
        
        parentWidth = getComputedStyle(selectedCircle.parentElement).width
        parentWidth = Number(parentWidth.replaceAll('px', ''))
    }

    const mouseMoveHandler = (e) => {
        if(isMouseDown.value)
        {
            let numberDirection = (e.x - mousePosition.x)
            let distance = Math.sqrt((e.x - mousePosition.x) ** 2 + (e.y - mousePosition.y) ** 2)
            const getDistance = () => {
                return (numberDirection>=0) ? (+ distance) : (-distance)
            }

            if(parentWidth < (prevPosition + getDistance() + 20) || (prevPosition + getDistance() < -20)) return

            selectedCircle.style.left = Math.floor(prevPosition + getDistance()) + 'px'
        }
    }

    const mouseUpHandler = (e) => {
        if(isMouseDown.value){
            isMouseDown.value = false
            selectedCircle.parentElement.style.zIndex = 6
            selectedCircle.style.zIndex = 7
            window.removeEventListener('mousemove', mouseMoveHandler)

            emit('onDragEnd', props.item, selectedCircle)
        }
    }

    onMounted(() => {
        window.addEventListener('mousemove', mouseMoveHandler)
        window.addEventListener('mouseup', mouseUpHandler)
    })

    onUnmounted(() => {
        window.removeEventListener('mousemove', mouseMoveHandler)
        window.removeEventListener('mouseup', mouseUpHandler)
    })
</script>

<style scoped>
    .circle{
        box-shadow: 0px 3.58419px 8.96047px rgba(0, 0, 0, 0.15);
        background-color: v-bind('props.bg');
        left: calc(v-bind('props.left') - 20px);
        z-index: 6;
    }
</style>